import React from "react";

export default function Preloader() {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100vw",
                height: "100vh",
                background: "#000000",
                color: "#ffffff", // Customize the background color
            }}
        >
            <h1>Loading...</h1>
        </div>
    );
}
