import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    email: "",
    user : {},
    newDataId  : "",
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAccessToken: (state, action) => {
            state.token = action.payload;
        },
        seveUser: (state, action) => {
            state.user = action.payload;
        },
       
        logout: (state, action) => {
            state.user = {};
            state.token = "";
            localStorage.removeItem('token')
        },
        savenewDataId: (state, action) => {
            state.newDataId = action.payload;
        },
    },
});

export const { setAccessToken, seveUser , logout,savenewDataId } = authSlice.actions;

export default authSlice.reducer;