import React, { useRef, useEffect, useState } from "react";
import { Html, PerspectiveCamera } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { gsap } from "gsap";
import { Sine } from "gsap";
import Content from "./contect";
import { useSelector } from 'react-redux';
// Linear interpolation function
function lerp(start, end, t) {
    return start + (end - start) * t;
}

export default function Fiber() {

    const [maxpos, setMaxpos] = useState(420);
    const [minpos, setMinpos] = useState(null);
    const [data, setData] = useState({});
    const newDataId = useSelector(state => state.auth.newDataId)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/getById/${newDataId}`);
                if (!response.ok) throw new Error("Network response was not ok");
                const jsonData = await response.json();
                console.log(jsonData, "Niahishishd");

                const positions = jsonData?.data?.Items.map(item => item?.POSITION);

                const newMinpos = Math.min(...positions) + 10;

                setMinpos(newMinpos);
                setData(jsonData?.data);
            } catch (error) {
                console.error("Error fetching data from the API:", error);
            }
        };

        fetchData();
    }, []);


    const camera = useRef();
    const [cameraPosition, setCameraPosition] = useState(maxpos);

    useEffect(() => {
        gsap.registerPlugin("scrollTo");

        const touchSpeedFactor = 0.9;

        const handleInput = (event) => {
            let zMovement = 0;

            if (event.deltaY) {
                // Mouse wheel input
                const t = (camera.current.position.z - minpos) / (maxpos - minpos);
                // Use the absolute value of newCameraPosition to calculate maxZMovement
                const maxZMovement = lerp(0.9, 0.03, Math.abs(t));
                zMovement = event.deltaY * maxZMovement;
            } else if (event.scale) {
                // Pinch input
                zMovement = event.scale * 10 * touchSpeedFactor;
            }

            let newCameraPosition = camera.current.position.z + zMovement;
            // Clamp the camera position within the range of minpos to maxpos
            newCameraPosition = Math.max(minpos, Math.min(maxpos, newCameraPosition));
            gsap.to(camera.current.position, {
                z: newCameraPosition,
                duration: 1.7,
                ease: Sine.easeOut,
                onUpdate: () => {
                    setCameraPosition(camera.current.position.z);
                },
            });
        };

        window.addEventListener("wheel", handleInput);

        return () => {
            window.removeEventListener("wheel", handleInput);
        };
    }, [minpos, maxpos]);


    useEffect(() => {
        gsap.registerPlugin("scrollTo");

        const touchSpeedFactor = 0.9;

        const handleTouchStart = (event) => {

            // Store the initial touch position when sliding starts
            const initialTouchY = event.touches[0].clientY;

            // Store the initial camera position
            const initialCameraPosition = camera.current.position.z;

            const handleTouchMove = (moveEvent) => {
                // Calculate the vertical movement distance
                const deltaY = moveEvent.touches[0].clientY - initialTouchY;

                // Calculate the new camera position based on the movement
                const newCameraPosition = initialCameraPosition - deltaY * touchSpeedFactor;

                // Clamp the camera position within the range of 125 to 430
                const clampedCameraPosition = Math.max(minpos, Math.min(maxpos, newCameraPosition)
                );

                // Update the camera position
                gsap.to(camera.current.position, {
                    z: clampedCameraPosition,
                    duration: 1.7,
                    ease: Sine.easeOut,
                    onUpdate: () => {
                        setCameraPosition(clampedCameraPosition);
                    },
                });
            };

            const handleTouchEnd = () => {
                // Remove touch move and touch end listeners
                window.removeEventListener("touchmove", handleTouchMove);
                window.removeEventListener("touchend", handleTouchEnd);
            };

            // Add touch move and touch end listeners to the document
            window.addEventListener("touchmove", handleTouchMove);
            window.addEventListener("touchend", handleTouchEnd);
        };

        // Add touch start listener to the document
        window.addEventListener("touchstart", handleTouchStart);

        return () => {
            // Remove touch start listener when the component unmounts
            window.removeEventListener("touchstart", handleTouchStart);
        };
    }, []);

    // Define a function to update the camera position
    const updateCameraPosition = () => {
        gsap.to(camera.current.position, {
            z: newPosition,
            duration: 1.7,
            ease: Sine.easeOut,
            onUpdate: () => {
                setCameraPosition(newPosition);
            },
        });
    };

    const handleSliderChange = (sliderValue) => {
        const newPosition = (maxpos + minpos) - parseFloat(sliderValue); // Reverse the value
        console.log(newPosition, cameraPosition, minpos, maxpos)


        gsap.to(camera.current.position, {
            z: newPosition,
            duration: 1.7,
            ease: Sine.easeOut,
        });
        setCameraPosition(newPosition);
    };
    return (
        <>
            <Canvas>
                <PerspectiveCamera makeDefault position={[0, 0, maxpos]} ref={camera} />
                <Content updateCameraPosition={updateCameraPosition} />
            </Canvas>

            <div className="slider-container" style={{ background: 'transparent', boxShadow: 'none', border: 'none', zIndex: '99999999' }}>
                <div style={{ textAlign: "center" }}>
                    <img src="/watermark.jpg" style={{ width: "400px", }} />
                </div>
                <input
                    type="range"
                    className="slider"
                    min={minpos}
                    max={maxpos}
                    step="0.1"
                    value={(maxpos + minpos) - cameraPosition} // Reverse the value
                    onTouchStart={(e) => {
                        // Prevent touch events on the slider from propagating
                        e.stopPropagation();
                    }}
                    onChange={(e) => handleSliderChange(e.target.value)}
                />
            </div>
        </>
    );
}