import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../Redux/reducers/authSlice";

function Input_form() {
  const [isEditAble, setEditAble] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const [data, setdata] = useState({
    name: "",
    IMAGE: "",
    TOTAL_TICKETS: "",
    TOTAL_TICKETS_COLOR: "",
    TOTAL_TICKETS_TEXT: "",
    TOTAL_TICKETS_TEXT_IMAGES: "",
    TOTAL_LOSING: "",
    TOTAL_LOSING_COLOR: "",
    TOTAL_LOSING_TEXT: "",
    TOTAL_LOSING_TEXT_IMAGES: "",
    TOTAL_WINNINGS: "",
    TOTAL_WINNINGS_COLOR: "",
    TOTAL_WINNINGS_TEXT: "",
    TOTAL_WINNINGS_TEXT_IMAGES: "",
    Items: [],
  });

  const handleChangeInputValue = (e) => {
    const { name, value } = e.target;
    setdata((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const newDataId = useSelector((state) => state.auth.newDataId);

  const handleChangeItemValue = (index, field, value) => {
    setdata((prev) => {
      const updatedItems = [...prev.Items];
      updatedItems[index][field] = value;
      return {
        ...prev,
        Items: updatedItems,
      };
    });
  };

  useEffect(() => {
    GetDataId();
  }, []);

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, [token]);

  const handleLogout = () => {
    dispatch(logout());
  };

  const GetDataId = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/getById/${newDataId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        }
      );

      const jsonData = await response.json();
      if (jsonData.status === 1) {
        console.log(jsonData);
        setEditAble(true);
        setdata(jsonData.data);
      } else {
        toast.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSaveChange = async () => {
    try {
      for (const key in data) {
        const formattedKey = key.replace(/_/g, ' '); // Replace underscores with spaces
        if (!data[key]) {
          toast.error(`Please fill in the ${formattedKey} field.`);
          return; // Validation failed
        }
      }

      if (data.Items.length === 0) {
        toast.error("Items of the scroller is empty. Please add items.");
        return; // Don't submit the API
      }
      data.Items.sort((a, b) => a.POSITION - b.POSITION);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/updateData/${newDataId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        toast.success("Data updated successfully!");
      } else {
        toast.error("Failed to update data");
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleDeleteItem = async (itemToDeleteId) => {
    try {
      if (!itemToDeleteId) {
        toast.error("No item selected for deletion");
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/delete-item/${newDataId}/${itemToDeleteId}`, // Replace with your API endpoint
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`
          },
        }
      );

      if (response.ok) {
        toast.success("Item deleted successfully!");
        GetDataId();
        // Clear the itemToDeleteId state after successful deletion
        setItemToDeleteId(null);
        // Refresh the data
        GetDataId();
      } else {
        toast.error("Failed to delete item");
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };
  const handleUpdateItem = async (itemId, index) => {
    try {
      const updatedItemData = {
        // Define the updated item data here
        // For example:
        USD: document.getElementById(`USD-${index}`).value,
        TOTAL_PRIZES: document.getElementById(`TOTAL_PRIZES-${index}`).value,
        ODDS_WINNINGS: document.getElementById(`ODDS_WINNINGS-${index}`).value,
        IMG: document.getElementById(`IMG-${index}`).value,
        COLOR: document.getElementById(`COLOR-${index}`).value,
        // TEXT: document.getElementById(`TEXT-${index}`).value,
        POSITION: document.getElementById(`POSITION-${index}`).value,
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/update-item/${newDataId}/${itemId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`
          },
          body: JSON.stringify(updatedItemData), // Convert the data to JSON
        }
      );

      if (response.ok) {
        // Handle success (item updated successfully)
        toast.success("Item updated successfully!");
      } else {
        // Handle failure (item update failed)
        console.error("Failed to update item");
      }
    } catch (error) {
      console.error("Error updating item:", error);
    }
  };

  const handleAddNewItem = async () => {
    try {
      const newId = data.Items.length + 1;
      const newItemData = {
        newItems: [
          {
            id: newId,
            USD: document.getElementById("newUSD").value,
            TOTAL_PRIZES: document.getElementById("newTotalPrizes").value,
            ODDS_WINNINGS: document.getElementById("newOddsWinnings").value,
            IMG: document.getElementById("newIMG").value,
            COLOR: document.getElementById("newColor").value,
            // TEXT: document.getElementById("newText").value,
            POSITION: document.getElementById("newPosition").value,
          },
        ],
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/lottery/${newDataId}/add-items`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`
          },
          body: JSON.stringify(newItemData),
        }
      );

      if (response.ok) {
        toast.success("New item added successfully!");
        document.getElementById("newUSD").value = "";
        document.getElementById("newTotalPrizes").value = "";
        document.getElementById("newOddsWinnings").value = "";
        document.getElementById("newIMG").value = "";
        document.getElementById("newColor").value = "";
        // document.getElementById("newText").value = "";
        document.getElementById("newPosition").value = "";
        GetDataId();
      } else {
        toast.error("Failed to add new item");
      }
    } catch (error) {
      console.error("Error adding new item:", error);
    }
  };

  const handleDownload = () => {
    // Create an array to store all CSV lines
    const csvLines = [];

    // Initialize a counter for assigning IDs
    let itemIdCounter = 1;

    // Headers for the main data
    const mainHeaders = [
      "name", "IMAGE", "TOTAL_TICKETS", "TOTAL_TICKETS_COLOR",
      "TOTAL_TICKETS_TEXT", "TOTAL_TICKETS_TEXT_IMAGES", "TOTAL_LOSING", "TOTAL_LOSING_COLOR",
      "TOTAL_LOSING_TEXT", "TOTAL_LOSING_TEXT_IMAGES", "TOTAL_WINNINGS", "TOTAL_WINNINGS_COLOR",
      "TOTAL_WINNINGS_TEXT", "TOTAL_WINNINGS_TEXT_IMAGES"
    ];
    csvLines.push(mainHeaders.join(','));

    const mainData = [];
    mainHeaders.forEach(header => {
      mainData.push(`"${data[header] || ""}"`);
    });
    csvLines.push(mainData.join(','));

    // Process the Items array
    if (data.Items && data.Items.length > 0) {
      csvLines.push("Items");  // Add the header for "Items"

      const itemHeaders = [
        "id", "USD", "TOTAL_PRIZES", "ODDS_WINNINGS",
        "IMG", "COLOR", "POSITION"
      ];
      csvLines.push(itemHeaders.join(','));

      data.Items.forEach(item => {
        const itemData = [itemIdCounter++];
        itemHeaders.slice(1).forEach(header => {  // slice to exclude the id
          itemData.push(`"${item[header] || ""}"`);
        });
        csvLines.push(itemData.join(','));
      });
    }

    // Combine all CSV lines into a single CSV string
    const csvData = csvLines.join('\n');

    // Create a Blob containing the CSV data
    const blob = new Blob([csvData], { type: 'text/csv' });

    // Create a URL for the Blob
    const csvURL = window.URL.createObjectURL(blob);

    // Create a link for the user to download the CSV
    const link = document.createElement('a');
    link.href = csvURL;
    link.download = `${data.name}-${new Date()}.csv`;  // Set the filename for the downloaded file

    // Simulate a click to trigger the download
    link.click();

    // Clean up by revoking the URL
    window.URL.revokeObjectURL(csvURL);
  };



  return (
    <>
      <div className="main--div--dashboard">
        <div className="header--top flex items-center gap-5 justify-between p-20 z-10 relative">
          <div className="right--col">
            <Link to="/" className="text-none">
              <h1>Dashboard</h1>
            </Link>
          </div>
          <div className="left--col">
            <div className="logout">
              <button className="btn--common bg--white" onClick={handleLogout}>
                Logout
              </button>
            </div>
          </div>
        </div>
        <div className="back--btn m-auto w-m-1700 mt-20">
          <Link to="/dashboard" className="btn--common bg--primary flex items-center justify-center"><span style={{ height: '20px' }}>&larr;</span > Back</Link>
        </div>
        <div className="custom--form input--form--container relative transform-none top-0 left-0 w-m-1700 m-auto mt-20 mb-20">
          <p className="mt-20 note--text">Note: Position can not be greater then 327 </p>
          <h1 className="mt-0 mt-20">Edit INFINITE SCROLLER</h1>
          <div className="enter--input--form">
            <div className="col-3 box--list">
              <div className="form--group">
                <label htmlFor="Scroller Name:">Scroller Name:</label>
                <input
                  required
                  type="text"
                  value={data.name}
                  onChange={handleChangeInputValue}
                  name="name"
                  id="name"
                />
              </div>
              <div className="form--group">
                <label htmlFor="Scroller Image:">Scroller Image:</label>
                <input
                  required
                  type="text"
                  value={data.IMAGE}
                  onChange={handleChangeInputValue}
                  name="IMAGE"
                  id="IMAGE"
                />
              </div>
            </div>
            <div className="col-3 box--list">
              <div className="form--group">
                <label htmlFor="TOTAL_TICKETS">TOTAL TICKETS:</label>
                <input
                  required
                  type="text"
                  value={data.TOTAL_TICKETS}
                  onChange={handleChangeInputValue}
                  name="TOTAL_TICKETS"
                  id="TOTAL_TICKETS"
                />
              </div>
              <div className="form--group">
                <label htmlFor="TOTAL_TICKETS_COLOR">
                  TOTAL TICKETS COLOR:
                </label>
                <input
                  required
                  type="text"
                  value={data.TOTAL_TICKETS_COLOR}
                  onChange={handleChangeInputValue}
                  name="TOTAL_TICKETS_COLOR"
                  id="TOTAL_TICKETS_COLOR"
                />
              </div>
              <div className="form--group">
                <label htmlFor="TOTAL_TICKETS_TEXT">TOTAL TICKETS TEXT:</label>
                <input
                  required
                  type="text"
                  value={data.TOTAL_TICKETS_TEXT}
                  onChange={handleChangeInputValue}
                  name="TOTAL_TICKETS_TEXT"
                  id="TOTAL_TICKETS_TEXT"
                />
              </div>
              <div className="form--group">
                <label htmlFor="TOTAL_TICKETS_TEXT">TOTAL TICKETS IMAGES</label>
                <input
                  required
                  type="text"
                  value={data.TOTAL_TICKETS_TEXT_IMAGES}
                  onChange={handleChangeInputValue}
                  name="TOTAL_TICKETS_TEXT_IMAGES"
                  id="TOTAL_TICKETS_TEXT_IMAGES"
                />
              </div>

            </div>

            <div className="col-3 box--list">
              <div className="form--group">
                <label htmlFor="TOTAL_LOSING">TOTAL LOSING:</label>
                <input
                  required
                  type="text"
                  name="TOTAL_LOSING"
                  value={data.TOTAL_LOSING}
                  onChange={handleChangeInputValue}
                  id="TOTAL_LOSING"
                />
              </div>
              <div className="form--group">
                <label htmlFor="TOTAL_LOSING_COLOR">TOTAL LOSING COLOR:</label>
                <input
                  required
                  type="text"
                  name="TOTAL_LOSING_COLOR"
                  value={data.TOTAL_LOSING_COLOR}
                  onChange={handleChangeInputValue}
                  id="TOTAL_LOSING_COLOR"
                />
              </div>
              <div className="form--group">
                <label htmlFor="TOTAL_LOSING_TEXT">TOTAL LOSING TEXT:</label>
                <input
                  required
                  type="text"
                  name="TOTAL_LOSING_TEXT"
                  value={data.TOTAL_LOSING_TEXT}
                  onChange={handleChangeInputValue}
                  id="TOTAL_LOSING_TEXT"
                />
              </div>
              <div className="form--group">
                <label htmlFor="TOTAL_TICKETS_TEXT">TOTAL LOSING IMAGES</label>
                <input
                  required
                  type="text"
                  value={data.TOTAL_LOSING_TEXT_IMAGES}
                  onChange={handleChangeInputValue}
                  name="TOTAL_LOSING_TEXT_IMAGES"
                  id="TOTAL_LOSING_TEXT_IMAGES"
                />
              </div>
            </div>

            <div className="col-3 box--list">
              <div className="form--group">
                <label for="TOTAL_WINNINGS">TOTAL WINNINGS:</label>
                <input
                  required
                  type="text"
                  name="TOTAL_WINNINGS"
                  value={data.TOTAL_WINNINGS}
                  onChange={handleChangeInputValue}
                  id="TOTAL_WINNINGS"
                />
              </div>
              <div className="form--group">
                <label htmlFor="TOTAL_WINNINGS_COLOR">
                  TOTAL WINNINGS COLOR:
                </label>
                <input
                  required
                  type="text"
                  name="TOTAL_WINNINGS_COLOR"
                  value={data.TOTAL_WINNINGS_COLOR}
                  onChange={handleChangeInputValue}
                  id="TOTAL_WINNINGS_COLOR"
                />
              </div>
              <div className="form--group">
                <label htmlFor="TOTAL_WINNINGS_TEXT">
                  TOTAL WINNINGS TEXT:
                </label>
                <input
                  required
                  type="text"
                  name="TOTAL_WINNINGS_TEXT"
                  value={data.TOTAL_WINNINGS_TEXT}
                  onChange={handleChangeInputValue}
                  id="TOTAL_WINNINGS_TEXT"
                />
              </div>
              <div className="form--group">
                <label htmlFor="TOTAL_TICKETS_TEXT">TOTAL WINNINGS IMAGES</label>
                <input
                  required
                  type="text"
                  value={data.TOTAL_WINNINGS_TEXT_IMAGES}
                  onChange={handleChangeInputValue}
                  name="TOTAL_WINNINGS_TEXT_IMAGES"
                  id="TOTAL_WINNINGS_TEXT_IMAGES"
                />
              </div>
            </div>
          </div>
          <div className="table_data">
            <table className="w-full">
              <thead>
                <tr>
                  <th>USD</th>
                  <th>TOTAL PRIZES</th>
                  <th>ODDS WINNINGS</th>
                  <th>IMG</th>
                  <th>COLOR</th>
                  <th>POSITION</th>
                </tr>
              </thead>
              <tbody>
                {data?.Items?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="text"
                        className="w-full p-10"
                        value={item.USD}
                        id={`USD-${index}`}
                        onChange={(e) =>
                          handleChangeItemValue(index, "USD", e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="w-full p-10"
                        value={item.TOTAL_PRIZES}
                        id={`TOTAL_PRIZES-${index}`}
                        onChange={(e) =>
                          handleChangeItemValue(
                            index,
                            "TOTAL_PRIZES",
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="w-full p-10"
                        value={item.ODDS_WINNINGS}
                        id={`ODDS_WINNINGS-${index}`}
                        onChange={(e) =>
                          handleChangeItemValue(
                            index,
                            "ODDS_WINNINGS",
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="w-full p-10"
                        value={item.IMG}
                        id={`IMG-${index}`}
                        onChange={(e) =>
                          handleChangeItemValue(index, "IMG", e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="w-full p-10"
                        value={item.COLOR}
                        id={`COLOR-${index}`}
                        onChange={(e) =>
                          handleChangeItemValue(index, "COLOR", e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="w-full p-10"
                        value={item.POSITION}
                        id={`POSITION-${index}`}
                        onChange={(e) =>
                          handleChangeItemValue(
                            index,
                            "POSITION",
                            e.target.value
                          )
                        }
                      />
                    </td>
                    <td width={190}>
                      <div className="btn--custom--list flex">
                        <button
                          className="update-button btn--common bg--primary"
                          onClick={() => handleUpdateItem(item.id, index)}
                        >
                          Update
                        </button>
                        <button
                          className="delete-button btn--common bg--red ml-2"
                          onClick={() => handleDeleteItem(item.id)}
                        >
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="add_new_container">
            <table className="w-full add_new">
              <tbody>
                <tr>
                  <td>
                    <input
                      type="text"
                      id="newUSD"
                      className="w-full p-10"
                      placeholder="USD"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      id="newTotalPrizes"
                      className="w-full p-10"
                      placeholder="TOTAL_PRIZES"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      id="newOddsWinnings"
                      className="w-full p-10"
                      placeholder="ODDS_WINNINGS	"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      id="newIMG"
                      className="w-full p-10"
                      placeholder="IMG"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      id="newColor"
                      className="w-full p-10"
                      placeholder="COLOR"
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      id="newPosition"
                      className="w-full p-10"
                      placeholder="POSITION"
                    />
                  </td>
                  <td width={190}>
                    <button
                      className="update-button btn--common bg--success w-m-full"
                      onClick={handleAddNewItem}
                    >
                      Add New
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="save_btn_change">
            <button onClick={handleSaveChange}>Save Change</button>
            <button onClick={handleDownload}>Download Csv</button>
          </div>
        </div>
      </div >
    </>
  );
}

export default Input_form;
