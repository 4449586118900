import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout, savenewDataId } from '../../Redux/reducers/authSlice';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';


function Dashboard() {
  const [Data, setData] = useState(null)
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [cnfPassword, setCnfPassword] = useState('');

  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const navigate = useNavigate()


  useEffect(() => {
    if (!token) {
      navigate('/');
    }
    fetchData();
  }, [token]);

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/getData`, {
        method: 'GET',
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json',
        },
      });
      const jsonData = await response.json();

      if (jsonData.status === 1) {
        setData(jsonData.data);
      } else {
        if (jsonData.message !== 'Auth_error') {
          toast.error(jsonData.message);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Define an object that maps 'name' attributes to state setter functions
  const stateSetterMap = {
    email: setEmail,
    newPassword: setNewPassword,
    cnfPassword: setCnfPassword,
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Use the stateSetterMap to update the state based on 'name'
    const setStateFunction = stateSetterMap[name];
    if (setStateFunction) {
      setStateFunction(value);
    }
  };

  const handleDelete = async (itemId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/deleteData/${itemId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json',
        },
      });
      const jsonData = await response.json();

      if (jsonData.status === 1) {
        toast.success(jsonData.message);
        fetchData();
      } else {
        toast.error(jsonData.message);
      }
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const GetDataId = async (itemname, itemId) => {
    navigate(`/dashboard/${itemname}`);
    dispatch(savenewDataId(itemId))

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/getById/${itemId}`, {
        method: 'GET',
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (jsonData.status === 1) {
        const jsonData = await response.json();
        console.log(jsonData)
      } else {
        toast.error(jsonData.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const Edit = (ItemId) => {
    dispatch(savenewDataId(ItemId))
    navigate(`/dashboard/${ItemId}/update-form`)
  }


  const handleSubmitMail = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/update-email`, {
        method: 'PUT',
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "newEmail": email }),
      });

      const jsonData = await response.json();

      if (jsonData.status === 1) {
        toast.success(jsonData.message);
        setEmail("")
      } else {
        toast.error(jsonData.message);
      }
    } catch (error) {
      console.error('Error submitting email:', error);
    }
  };
  const handleChangePassword = async () => {
    try {
      if (newPassword !== cnfPassword) {
        toast.error("New password and confirm password do not match");
        return;
      }
      const response = await fetch(`${process.env.REACT_APP_API_URL}/update-password`, {
        method: 'PUT',
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ newPassword }),
      });

      const jsonData = await response.json();

      if (jsonData.status === 1) {
        toast.success(jsonData.message);
        setNewPassword("");
        setCnfPassword("");
      } else {
        toast.error(jsonData.message);
      }
    } catch (error) {
      console.error('Error updating password:', error);
    }
  };
  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/upload-bulk-data`, {
          method: 'POST',
          headers: {
            Authorization: token,
          },
          body: formData,
        });
        const jsonData = await response.json();

        if (jsonData.status === 1) {
          toast.success('CSV data uploaded and saved to the database');
          fetchData();
          window.location.reload();
        } else {
          toast.error('An error occurred: ' + jsonData.message);
        }
      } catch (error) {
        console.error('Error uploading CSV:', error);
        toast.error('An error occurred while uploading the CSV file.');
      }
    } else {
      toast.error('Please select a CSV file to upload.');
    }
  };

  const handleDownloadCsv = () => {
    fetch(`${process.env.REACT_APP_API_URL}/download-bulk-data`, {
      method: 'GET',
      headers: {
        'Content-Type': 'text/csv', // You may adjust the content type based on your server's response
        Authorization: token,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          // The response status is 200, indicating success
          return response.blob();
        } else {
          // Handle other response status codes (e.g., 404, 500) as needed
          throw new Error('Download failed');
        }
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Lottery.csv'; // Specify the filename here
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error during CSV download:', error);
        // Handle the error as needed (e.g., show an error message)
      });
  };

  return (
    <>
      <div className='main--div--dashboard'>
        <div className='header--top flex items-center gap-5 justify-between p-20'>
          <div className='right--col'>
            <h1>Dashboard</h1>
          </div>
          <div className='left--col'>
            <div className="logout">
              <button onClick={handleLogout} className='btn--common bg--white'>Logout</button>
            </div>
          </div>
        </div>

        <div className='import--export--section'>
          {/* <button className='btn--common bg--primary export--btn' title='Export CSV File' onClick={handleDownloadCsv}><img src="/download.png" width={15} height={15} /> Export Csv</button>
          <button className='btn--common bg--primary import--btn' title='Import CSV File' ><input type='file' onChange={handleFileChange} /><img src="/importfile.png" width={15} height={15}/> Import Csv</button> */}
        </div>

        <div className='update--profile--div flex gap-5'>
          <div className='col-6'>
            <div className="form--group">
              <label htmlFor="Change Admin Mail:">Change Admin Mail:</label>
              <input
                required
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={handleChange}
              />
            </div>
            <button className='btn--common bg--primary' onClick={handleSubmitMail}>Change Mail</button>
          </div>

          <div className='col-6'>
            <div className="form--group">
              <label htmlFor="Change Password:">Change Password:</label>
              <input
                required
                type="text"
                name="newPassword"
                value={newPassword}
                onChange={handleChange}
              />
            </div>
            <div className="form--group">
              <label htmlFor="Confirm Password:">Confirm Password:</label>
              <input
                required
                type="text"
                name="cnfPassword"
                value={cnfPassword}
                onChange={handleChange}
              />
            </div>
            <button className='btn--common bg--primary' onClick={handleChangePassword}>Change Password</button>
          </div>
        </div>

        {Data ? (
          <div className='container--list'>
            <div className='data--list m-auto w-fit-content p-20'>
              {Data.map((item) => (
                <div key={item._id} className='data--item'>
                  <button onClick={() => GetDataId(item.name, item._id)} className='item--1'>{item.name}</button>
                  <button type="button" className='item--2' onClick={() => Edit(item._id)}>Edit</button>


                  <button className='item--2' onClick={() => handleDelete(item._id)}>delete</button>
                </div>
              ))}

            </div>

            <div className='add--more text-center'>
              <Link to="/dashboard/add-new" className="text-none"><button className='btn--common bg--primary'>Add New</button></Link>
            </div>

          </div>
        ) : (
          <p>Loading data...</p>
        )}

      </div>

    </>
  )
}

export default Dashboard
