import React, { useState, useEffect, Suspense, lazy } from "react";
import Fiber from "./fiber";
import "@fontsource/bebas-neue";
import Preloader from "./preloader";
// // Use React.lazy to lazily load the Fiber component
// const Fiber = lazy(() => import("./fiber"));

export default function Endpoint() {

    return (
        <>
            <Suspense fallback={<Preloader />}>
                <Fiber />
            </Suspense>
        </>
    );
}
