import React, { useState, useEffect } from "react";
import { Plane, Box, Shape, Circle, Html, Ring, useTexture } from "@react-three/drei";
import { DoubleSide } from "three";
import { useSelector } from "react-redux";

export default function Content({ updateCameraPosition }) {
    const [selectedPrize, setSelectedPrize] = useState(null);
    const [Image, setImage] = useState("");
    const [DATA, setData] = useState({ Items: [] }); // Initialized Items as an empty array
    const newDataId = useSelector((state) => state.auth.newDataId);

    useEffect(() => {
        const handleEvent = (event) => {
            if (["gotpointercapture", "click"].includes(event.type)) {
                handleClick(event);
            } else {
                handleScroll(event);
            }
        };

        const eventTypes = [
            "wheel",
            "gesturechange",
            "touchstart",
            "gotpointercapture",
            "click",
        ];
        eventTypes.forEach((type) => window.addEventListener(type, handleEvent));

        const sliderContainer = document.querySelector(".slider-container");
        if (sliderContainer) {
            sliderContainer.addEventListener("gotpointercapture", handleEvent);
        }

        return () => {
            eventTypes.forEach((type) =>
                window.removeEventListener(type, handleEvent)
            );
            if (sliderContainer) {
                sliderContainer.removeEventListener("gotpointercapture", handleEvent);
            }
        };
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/getById/${newDataId}`
            );
            if (!response.ok) throw new Error("Network response was not ok");
            const jsonData = await response.json();


            setData(jsonData?.data);
            setImage(jsonData?.data?.IMAGE);
            // sendDataToParent(jsonData);
        } catch (error) {
            console.error("Error fetching data from the API:", error);
        }
    };

    const computeNewPosition = (basePosition, mobileOffset, desktopOffset) => {
        const offset = window.innerWidth < 750 ? mobileOffset : desktopOffset;
        return basePosition + offset;
    };

    const handleCircleClick = (index) => {
        if (index < 3) {
            const basePositions = [413, 393, 373];
            const newPosition = computeNewPosition(basePositions[index], 15, 8);
            updateCameraPosition(newPosition);
            setSelectedPrize(index);
        } else {
            const circlePosition = DATA.Items[index - 3].POSITION - 22;
            const newPosition = computeNewPosition(circlePosition, 37, 30);
            updateCameraPosition(newPosition);
            setSelectedPrize(index);
        }
    };

    const handleClick = () => {
        if (selectedPrize !== null) {
            setSelectedPrize(null);
        }
    };

    const handleScroll = () => {
        setSelectedPrize(null);
    };

    useEffect(() => {
        fetchData();
    }, [setData]);

    return (
        <>
            {["TOTAL_TICKETS", "TOTAL_LOSING", "TOTAL_WINNINGS"].map((key, index) => (
                <RenderCircleGroup
                    key={index}
                    position={[0, 0, 413 - index * 20]}
                    color={DATA[`${key}_COLOR`]}
                    image={
                        Image ||
                        "https://images.unsplash.com/photo-1694456860373-9e89e3bd5903?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=987&q=80"
                    }
                    label={key.replace("_", " ")}
                    text={DATA[`${key}_TEXT`]}
                    detail={DATA[key]}
                    // onClick={() => handleCircleClick(index)}
                    isSelected={selectedPrize === index}
                />
            ))}

            {DATA?.Items?.map((item, index) => (
                <RenderCircleGroup
                    key={index}
                    position={[0, 0, item?.POSITION]}
                    color={item?.COLOR}
                    image={item?.IMG}
                    label={`${item?.USD}`}
                    text={item?.TEXT}
                    oddWinnings={item?.ODDS_WINNINGS}
                    totalPrize={item?.TOTAL_PRIZES}
                    itemPrize={item?.TOTAL_PRIZES}
                    detail={[
                        { label: "USD", value: item?.USD },
                        { label: "Total Prizes", value: item?.TOTAL_PRIZES },
                        { label: "Odds of winning", value: item?.ODDS_WINNINGS },
                    ]}
                    // onClick={() => handleCircleClick(index + 3)}
                    isSelected={selectedPrize === index + 3}
                />
            ))}
        </>
    );
}

const RenderCircleGroup = ({
    position,
    color,
    image,
    label,
    text,
    detail,
    totalPrize,
    oddWinnings,
    itemPrize,
    onClick,
    isSelected,
}) => (
    <group position={position}>
        <Ring args={[1, 1.1, 64, 1, Math.PI * 2]}>
            <meshBasicMaterial side={DoubleSide} color={color} />
        </Ring>
        <Plane
            scale={[0.6, 0.4, 1]}
            position={[0, -0.5, 0]}
            renderOrder={1}
        >
            <meshBasicMaterial map={useTexture(image)} />
        </Plane>
        <Html center transform position={[0, 1.5, 0]}>
            <h5>{label}</h5>
        </Html>
        <Html center transform position={[0, 0.7, 0]} scale={0.8}>
            <div className="circle-text-container">
                <h6>{oddWinnings ? oddWinnings : text}</h6>
            </div>
        </Html>
        <Html center transform position={[0, -1.5, 0]}>
            {Array.isArray(detail) ? (
                detail.map((item, i) => (
                    <h6 key={i}>
                        <span> {item.label === "USD" && itemPrize}</span>
                    </h6>
                ))
            ) : (
                <h6>{detail}</h6>
            )}
        </Html>
        <Html center transform position={[0, -1.5, 0]}>
            <h6> {totalPrize}</h6>
        </Html>
        {isSelected && (
            <Html
                center
                transform
                as="div"
                wrapperClass="dialog"
                position={[2.5, 0, 0]}
            >
                <div className="box">
                    <div
                        className="close-button"
                        style={{
                            cursor: "pointer",
                            right: "2px",
                            position: "absolute",
                            fontSize: "10px",
                        }}
                        onClick={() => setSelectedPrize(null)}
                    >
                        &#10006;
                    </div>
                    {Array.isArray(detail) ? (
                        detail.map((item, i) => (
                            <p key={i} className="item-label">
                                {item.label}: <span className="item-value">{item.value}</span>
                            </p>
                        ))
                    ) : (
                        <p className="item-label">
                            {label}: <span className="item-value">{detail}</span>
                        </p>
                    )}
                </div>
            </Html>
        )}
    </group>
);
