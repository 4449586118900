import React, { useEffect, useState } from "react";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { setAccessToken, seveUser } from "../../Redux/reducers/authSlice";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";


function Login() {

  const [email, setemail] = useState("")
  const [password, setpassword] = useState("")

  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const navigate = useNavigate()


  
  useEffect(() => {
    if (token) {
        navigate('/dashboard');
    }
}, []);


  const handleLogin = async (e) => {
    e.preventDefault();
    try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password })
        });
        const resData = await res.json();
        if (res.status === 200) { // Check if the response status is OK (successful)
          dispatch(setAccessToken(resData.token));
          dispatch(seveUser(resData.user));
          navigate("/dashboard");
          toast.success(resData.message);
        } else {
          toast.error(resData.message);
          // Handle other error cases if needed
        }
    } catch (error) {
         console.log("Error" , error)
    }
};

  return (
    <div className="main--div--dashboard">
      <div className="custom--form">
        <h1>Login</h1>
        <form>
          <div className="form--group">
            <label htmlFor="username">Email</label>
            <input
              type="text"
              id="username"
              name="username"
              placeholder="Enter Email"
              value={email}
              onChange={e=> setemail(e.target.value)}
              required
            />
          </div>

          <div className="form--group">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={e=> setpassword(e.target.value)}
              placeholder="Enter Password"
              required
            />
          </div>
          <div className="form--group mb-o">
            <button type="submit" onClick={handleLogin}>
              <span>Login</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
