import { StrictMode } from 'react'
import Endpoint from './components/endpoint'
import './style.css'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from "./Redux/store";
import RootRouter from './components/RootRouter'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const root = ReactDOM.createRoot(document.querySelector('#root'))

root.render(
    <>
        <StrictMode>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
          <RootRouter/>
          <ToastContainer />
            {/* <Endpoint /> */}
    </PersistGate>
            </Provider>
         </StrictMode>
    </>
)