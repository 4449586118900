import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link, useNavigate } from 'react-router-dom';
import { logout } from '../../Redux/reducers/authSlice';

function AddNew() {
  const [isEditAble, setEditAble] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [file, setFile] = useState(null);
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const navigate = useNavigate()

  const [newItem, setNewItem] = useState({
    USD: "",
    TOTAL_PRIZES: "",
    ODDS_WINNINGS: "",
    IMG: "",
    COLOR: "",
    // TEXT: "",
    POSITION: 0,
  });

  const handleAddNew = () => {
    console.log("ADDDD BTN ", newItem);

    // Check if any of the fields in newItem are empty
    for (const key in newItem) {
      if (!newItem[key]) {
        toast.error(`Please fill in the ${key} field.`);
        return;
      }
    }

    // Check if newItem.POSITION is greater than 392
    if (newItem.POSITION > 392) {
      toast.error("Position cannot be greater than 392.");
      return;
    }

    // Calculate the new id based on the length of data.Items and increase by 1
    const newId = data.Items.length + 1;
    newItem.id = newId;

    console.log(newItem, "sdasjdhsaj");

    // Add the new item to the data.Items array
    setdata((prev) => ({
      ...prev,
      Items: [...prev.Items, newItem],
    }));
  }



  const [data, setdata] = useState({
    name: "",
    IMAGE: "",
    TOTAL_TICKETS: "",
    TOTAL_TICKETS_COLOR: "",
    TOTAL_TICKETS_TEXT: "",
    TOTAL_TICKETS_TEXT_IMAGES: "",
    TOTAL_LOSING: "",
    TOTAL_LOSING_COLOR: "",
    TOTAL_LOSING_TEXT: "",
    TOTAL_LOSING_TEXT_IMAGES: "",
    TOTAL_WINNINGS: "",
    TOTAL_WINNINGS_COLOR: "",
    TOTAL_WINNINGS_TEXT: "",
    TOTAL_WINNINGS_TEXT_IMAGES: "",
    Items: [],
  });

  const handleChangeInputValue = (e) => {
    const { name, value } = e.target;
    setdata((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const newDataId = useSelector((state) => state.auth.newDataId);

  // const handleChangeItemValue = (index, field, value) => {
  //   setdata((prev) => {
  //     const updatedItems = [...prev.Items];
  //     updatedItems[index][field] = value;
  //     return {
  //       ...prev,
  //       Items: updatedItems,
  //     };
  //   });
  // };
  useEffect(() => {
    if (!token) {
      navigate('/');
    }
  }, [token])

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleSaveChange = async () => {
    try {
      for (const key in data) {
        const formattedKey = key.replace(/_/g, ' '); // Replace underscores with spaces
        if (!data[key]) {
          toast.error(`Please fill in the ${formattedKey} field.`);
          return; // Validation failed
        }
      }

      if (data.Items.length === 0) {
        toast.error("Items of the scroller is empty. Please add items.");
        return; // Don't submit the API
      }
      let shouldSubmitAPI = true; // Initialize a flag to determine if API should be submitted

      data.Items.forEach((item, index) => {
        if (item.POSITION > 392) {
          toast.error("Position is greater than 392 for item at index " + item);// Show a success toast

          shouldSubmitAPI = false; // Set the flag to false if the condition is met
          return; // Stop further iteration
        }
      });

      if (!shouldSubmitAPI) {
        // If the flag is false, don't submit the API
        return;
      }
      data.Items.sort((a, b) => b.POSITION - a.POSITION);


      const response = await fetch(`${process.env.REACT_APP_API_URL}/insertData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`, // Include your authentication token if needed
        },
        body: JSON.stringify(data), // Send your data object as JSON
      });

      if (response.ok) {
        // API call successful, you can handle the response here
        const result = await response.json();
        setApiResponse(result);
        console.log("API Response:", result);
        toast.success("New data saved successfully"); // Show a success toast
      } else {
        // API call failed, handle the error here
        console.error("API Error:", response.statusText);
        // You can display an error message to the user if needed
        toast.error("Failed to save changes");
      }
    } catch (error) {
      console.error("API Error:", error);
      // Handle any network or other errors here
      toast.error("An error occurred while saving changes");
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please select a CSV file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('singlefile', file); // Use 'csvFile' as the field name
    console.log(formData)
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/upload-single-data`, {
        method: 'POST',
        headers: {
          Authorization: token, // Include your authentication token if needed
        },
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.status === 1) {
          alert('CSV data uploaded and saved to the database.');
        } else {
          alert('An error occurred while saving data to the database.');
        }
      } else {
        alert('An error occurred while uploading and processing the CSV file.');
      }
    } catch (error) {
      console.error(error);
      alert('An error occurred while uploading and processing the CSV file.');
    }
  };


  useEffect(() => {
    if (!token) {
      navigate('/');
    };
  }, [token]);


  const handleDownload = () => {
    // Create an array to store all CSV lines
    const csvLines = [];

    // Initialize a counter for assigning IDs
    let itemIdCounter = 1;

    // Headers for the main data
    const mainHeaders = [
      "name", "IMAGE", "TOTAL_TICKETS", "TOTAL_TICKETS_COLOR",
      "TOTAL_TICKETS_TEXT", "TOTAL_LOSING", "TOTAL_LOSING_COLOR",
      "TOTAL_LOSING_TEXT", "TOTAL_WINNINGS", "TOTAL_WINNINGS_COLOR",
      "TOTAL_WINNINGS_TEXT"
    ];
    csvLines.push(mainHeaders.join(','));

    const mainData = [];
    mainHeaders.forEach(header => {
      mainData.push(`"${data[header] || ""}"`);
    });
    csvLines.push(mainData.join(','));

    // Process the Items array
    if (data.Items && data.Items.length > 0) {
      csvLines.push("Items");  // Add the header for "Items"

      const itemHeaders = [
        "id", "USD", "TOTAL_PRIZES", "ODDS_WINNINGS",
        "IMG", "COLOR", "TEXT", "Position"
      ];
      csvLines.push(itemHeaders.join(','));

      data.Items.forEach(item => {
        const itemData = [itemIdCounter++];
        itemHeaders.slice(1).forEach(header => {  // slice to exclude the id
          itemData.push(`"${item[header] || ""}"`);
        });
        csvLines.push(itemData.join(','));
      });
    }

    // Combine all CSV lines into a single CSV string
    const csvData = csvLines.join('\n');

    // Create a Blob containing the CSV data
    const blob = new Blob([csvData], { type: 'text/csv' });

    // Create a URL for the Blob
    const csvURL = window.URL.createObjectURL(blob);

    // Create a link for the user to download the CSV
    const link = document.createElement('a');
    link.href = csvURL;
    // link.download = 'data.csv';  // Set the filename for the downloaded file
    link.download = `${data.name}-${new Date()}.csv`;  // Set the filename for the downloaded file

    // Simulate a click to trigger the download
    link.click();

    // Clean up by revoking the URL
    window.URL.revokeObjectURL(csvURL);
  };



  return (
    <>
      <div className='main--div--dashboard'>
        <div className='header--top flex items-center gap-5 justify-between p-20 z-10 relative'>
          <div className='right--col'>
            <Link to="/" className="text-none"><h1>Dashboard</h1></Link>
          </div>
          <div className='left--col'>
            <div className="logout">
              <button className='btn--common bg--white' onClick={handleLogout}>Logout</button>
            </div>
          </div>
        </div>
        <div className="back--btn m-auto w-m-1700 mt-20">
          <Link to="/dashboard" className="btn--common bg--primary flex items-center justify-center"><span style={{ height: '20px' }}>&larr;</span> Back</Link>
        </div>
        <div className="custom--form input--form--container relative transform-none top-0 left-0 w-m-1700 m-auto mt-20 mb-20">
          <p className="mt-20 note--text">Note: Position can not be greater then 327 </p>
          <h1 className="mt-0 mt-10">Add New Infinite Scroller </h1>

          <div className="save_btn_change export--data--add">
            <input type="file" accept=".csv" name="file" onChange={handleFileChange} />
            <button onClick={handleUpload}>Upload Csv</button>
          </div>

          <div className="enter--input--form">
            <div className="col-3 box--list">
              <div className="form--group">
                <label htmlFor="Scroller Name">Scroller Name:</label>
                <input
                  required
                  type="text"
                  name="name"
                  value={data.name}
                  onChange={handleChangeInputValue}
                  id="name"
                />
              </div>
              <div className="form--group">
                <label htmlFor="Scroller Image">Scroller IMAGE:</label>
                <input
                  required
                  type="text"
                  name="IMAGE"
                  value={data.IMAGE}
                  onChange={handleChangeInputValue}
                  id="IMAGE"
                />
              </div>
            </div>
            <div className="col-3 box--list">
              <div className="form--group">
                <label htmlFor="TOTAL_TICKETS">TOTAL TICKETS:</label>
                <input
                  required
                  type="text"
                  value={data.TOTAL_TICKETS}
                  onChange={handleChangeInputValue}
                  name="TOTAL_TICKETS"
                  id="TOTAL_TICKETS"
                />
              </div>
              <div className="form--group">
                <label htmlFor="TOTAL_TICKETS_COLOR">
                  TOTAL TICKETS COLOR:
                </label>
                <input
                  required
                  type="text"
                  value={data.TOTAL_TICKETS_COLOR}
                  onChange={handleChangeInputValue}
                  name="TOTAL_TICKETS_COLOR"
                  id="TOTAL_TICKETS_COLOR"
                />
              </div>
              <div className="form--group">
                <label htmlFor="TOTAL_TICKETS_TEXT">TOTAL TICKETS TEXT:</label>
                <input
                  required
                  type="text"
                  value={data.TOTAL_TICKETS_TEXT}
                  onChange={handleChangeInputValue}
                  name="TOTAL_TICKETS_TEXT"
                  id="TOTAL_TICKETS_TEXT"
                />
              </div>
              <div className="form--group">
                <label htmlFor="TOTAL_TICKETS_TEXT">TOTAL TICKETS IMAGES</label>
                <input
                  required
                  type="text"
                  value={data.TOTAL_TICKETS_TEXT_IMAGES}
                  onChange={handleChangeInputValue}
                  name="TOTAL_TICKETS_TEXT_IMAGES"
                  id="TOTAL_TICKETS_TEXT_IMAGES"
                />
              </div>

            </div>

            <div className="col-3 box--list">
              <div className="form--group">
                <label htmlFor="TOTAL_LOSING">TOTAL LOSING:</label>
                <input
                  required
                  type="text"
                  name="TOTAL_LOSING"
                  value={data.TOTAL_LOSING}
                  onChange={handleChangeInputValue}
                  id="TOTAL_LOSING"
                />
              </div>
              <div className="form--group">
                <label htmlFor="TOTAL_LOSING_COLOR">TOTAL LOSING COLOR:</label>
                <input
                  required
                  type="text"
                  name="TOTAL_LOSING_COLOR"
                  value={data.TOTAL_LOSING_COLOR}
                  onChange={handleChangeInputValue}
                  id="TOTAL_LOSING_COLOR"
                />
              </div>
              <div className="form--group">
                <label htmlFor="TOTAL_LOSING_TEXT">TOTAL LOSING TEXT:</label>
                <input
                  required
                  type="text"
                  name="TOTAL_LOSING_TEXT"
                  value={data.TOTAL_LOSING_TEXT}
                  onChange={handleChangeInputValue}
                  id="TOTAL_LOSING_TEXT"
                />
              </div>

              <div className="form--group">
                <label htmlFor="TOTAL_TICKETS_TEXT">TOTAL LOSING  IMAGES</label>
                <input
                  required
                  type="text"
                  value={data.TOTAL_LOSING_TEXT_IMAGES}
                  onChange={handleChangeInputValue}
                  name="TOTAL_LOSING_TEXT_IMAGES"
                  id="TOTAL_LOSING_TEXT_IMAGES"
                />
              </div>
            </div>

            <div className="col-3 box--list">
              <div className="form--group">
                <label for="TOTAL_WINNINGS">TOTAL WINNINGS:</label>
                <input
                  required
                  type="text"
                  name="TOTAL_WINNINGS"
                  value={data.TOTAL_WINNINGS}
                  onChange={handleChangeInputValue}
                  id="TOTAL_WINNINGS"
                />
              </div>
              <div className="form--group">
                <label htmlFor="TOTAL_WINNINGS_COLOR">
                  TOTAL WINNINGS COLOR:
                </label>
                <input
                  required
                  type="text"
                  name="TOTAL_WINNINGS_COLOR"
                  value={data.TOTAL_WINNINGS_COLOR}
                  onChange={handleChangeInputValue}
                  id="TOTAL_WINNINGS_COLOR"
                />
              </div>
              <div className="form--group">
                <label htmlFor="TOTAL_WINNINGS_TEXT">
                  TOTAL WINNINGS TEXT:
                </label>
                <input
                  required
                  type="text"
                  name="TOTAL_WINNINGS_TEXT"
                  value={data.TOTAL_WINNINGS_TEXT}
                  onChange={handleChangeInputValue}
                  id="TOTAL_WINNINGS_TEXT"
                />
              </div>
              <div className="form--group">
                <label htmlFor="TOTAL_TICKETS_TEXT">TOTAL WINNINGS IMAGES</label>
                <input
                  required
                  type="text"
                  value={data.TOTAL_WINNINGS_TEXT_IMAGES}
                  onChange={handleChangeInputValue}
                  name="TOTAL_WINNINGS_TEXT_IMAGES"
                  id="TOTAL_WINNINGS_TEXT_IMAGES"
                />
              </div>
            </div>
          </div>
          <div className="table_data">
            <table className="w-full">
              <thead>
                {data && data.Items.length > 0 ? (
                  <tr>
                    <th>USD</th>
                    <th>TOTAL PRIZES</th>
                    <th>ODDS WINNINGS</th>
                    <th>IMG</th>
                    <th>COLOR</th>
                    {/* <th>POSITION</th> */}
                    <th></th>
                  </tr>
                ) : null
                }
              </thead>
              <tbody>
                {data?.Items?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <input type="text" className="w-full p-10" value={item.USD} onChange={(e) => handleChangeItemValue(index, "USD", e.target.value)} />
                    </td>
                    <td>
                      <input type="text" className="w-full p-10" value={item.TOTAL_PRIZES} onChange={(e) => handleChangeItemValue(index, "TOTAL_PRIZES", e.target.value)} />
                    </td>
                    <td>
                      <input type="text" className="w-full p-10" value={item.ODDS_WINNINGS} onChange={(e) => handleChangeItemValue(index, "ODDS_WINNINGS", e.target.value)} />
                    </td>
                    <td>
                      <input type="text" className="w-full p-10" value={item.IMG} onChange={(e) => handleChangeItemValue(index, "IMG", e.target.value)} />
                    </td>
                    <td>
                      <input type="text" className="w-full p-10" value={item.COLOR} onChange={(e) => handleChangeItemValue(index, "COLOR", e.target.value)} />
                    </td>
                    <td>
                      <input type="text" className="w-full p-10" value={item.POSITION} onChange={(e) => handleChangeItemValue(index, "POSITION", e.target.value)} />
                    </td>
                    <td width={190}>
                      <div className="btn--custom--list flex">
                        <button className="update-button btn--common bg--primary">Update</button>
                        <button className="delete-button btn--common bg--red ml-2" onClick={() => console.log(item?.id)}>Delete</button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <table className="w-full add_new">
            <tbody>
              <tr>
                <td>
                  <input type="text" className="w-full p-10" placeholder="USD"
                    value={newItem.USD}
                    onChange={(e) => setNewItem({ ...newItem, USD: e.target.value })}
                  />
                </td>
                <td>
                  <input type="text" className="w-full p-10" placeholder="TOTAL PRIZES"
                    value={newItem.TOTAL_PRIZES}
                    onChange={(e) => setNewItem({ ...newItem, TOTAL_PRIZES: e.target.value })}
                  />
                </td>
                <td>
                  <input type="text" className="w-full p-10" placeholder="ODDS WINNINGS	"
                    value={newItem.ODDS_WINNINGS}
                    onChange={(e) => setNewItem({ ...newItem, ODDS_WINNINGS: e.target.value })}
                  />
                </td>
                <td>
                  <input type="text" className="w-full p-10" placeholder="IMG"
                    value={newItem.IMG}
                    onChange={(e) => setNewItem({ ...newItem, IMG: e.target.value })}
                  />
                </td>
                <td>
                  <input type="text" className="w-full p-10" placeholder="COLOR"
                    value={newItem.COLOR}
                    onChange={(e) => setNewItem({ ...newItem, COLOR: e.target.value })}
                  />
                </td>
                <td>
                  <input type="number" className="w-full p-10" placeholder="POSITION"
                    value={newItem.POSITION}
                    onChange={(e) => setNewItem({ ...newItem, POSITION: e.target.value })}
                  />
                </td>
                <td width={190}>
                  <button className="update-button btn--common bg--success w-m-full" onClick={handleAddNew}>Add New</button>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="save_btn_change flex data--download--btn">
            <button onClick={handleSaveChange}>Save Change</button>
            {/* <button onClick={handleDownload}>Download Csv</button> */}
          </div>

        </div >




      </div >

    </>
  );
}

export default AddNew;
