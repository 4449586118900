import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../login/login";
import Dashboard from "../login/Dashboard";
import PageNoteFound from "../login/PageNoteFound";
import Endpoint from "../endpoint";
import Input_form from "../login/input-form";
import AddNew from "../login/AddNew";
function RootRouter() {  
return (
    <Router>
         <Routes>
         <Route path="/" element={<Login />} />
         <Route path="/dashboard" element={<Dashboard />} />
         <Route path="/dashboard/:id/update-form" element={<Input_form />} />
         <Route path="/dashboard/add-new" element={<AddNew />} />
         <Route path="/dashboard/:id" element={<Endpoint />} />
         <Route path="*" element={<PageNoteFound />} />
         </Routes>
        </Router>
    );
}

export default RootRouter;
